<template>
	<ValidationForm #default="{ handleSubmit }">
		<ViewLayout>
			<template #header-title>
				Translate Extended Preferences
			</template>
			<template #header-caption>
				Add, edit and manage extended preference translations
			</template>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="extendedPreferenceTranslation.name"
									label="Extended Preference Key Name"
									disabled
								/>
							</v-col>
							<v-col cols="6">
								<TextField
									v-model="extendedPreferenceTranslation.languageName"
									label="Language"
									disabled
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<DataTable
									:headers="tableHeaders"
									:items="extendedPreferenceTranslation.translations"
									@click:row="manageDropdownTranslation"
								>
									<template #item.action="{ item }">
										<IconButton
											tooltip-text="Edit Extended Preference Translation"
											@click="manageDropdownTranslation(item)"
										>
											{{ userFullPermissions ? 'mdi-pencil' : 'mdi-eye' }}
										</IconButton>
										<IconButton
											v-if="userFullPermissions && item.extendedPreferenceTranslationId"
											tooltip-text="Delete Extended Preference Translation"
											@click.stop.prevent="dropdownTranslationToRemove = item"
										>
											mdi-trash-can
										</IconButton>
									</template>
								</DataTable>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(submit)"
						>
							{{ !isEdit ? 'Create' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
		<ManageExtendedPreferenceDropdownTranslationModal
			v-if="dropdownTranslationToEdit"
			:dropdown-translation-to-edit.sync="dropdownTranslationToEdit"
			:language-name="extendedPreferenceTranslation.languageName"
			@close="dropdownTranslationToEdit = null"
			@submitTranslation="submitDropdownTranslation"
		/>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import ManageExtendedPreferenceDropdownTranslationModal from './manage-extended-preference-dropdown-translation-modal.vue'
import { EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { EXTENDED_PREFERENCES } from '../../../router/route-names.js'
import { upsertExtendedPreferenceTranslation } from '../../../../../shared/utils/api/extended-preferences.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: {
		ViewLayout,
		ValidationForm,
		SectionCard,
		TextField,
		DataTable,
		IconButton,
		PageActionRow,
		SecondaryActionButton,
		PrimaryActionButton,
		ManageExtendedPreferenceDropdownTranslationModal
	},
	props: {
		extendedPreferenceToTranslate: Object
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			extendedPreferenceTranslation: JSON.parse(JSON.stringify(this.extendedPreferenceToTranslate)),
			dropdownTranslationToEdit: null,
			dropdownTranslationToRemove: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		userFullPermissions () {
			return this.checkPermission(EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS)
		},
		isEdit () {
			return !!this.extendedPreferenceTranslation.extendedPreferenceTranslationId
		},
		tableHeaders () {
			return [
				{
					value: 'originalValue',
					text: 'Dropdown Menu Item',
					width: '45%'
				},
				{
					value: 'value',
					text: 'Translated Dropdown Menu Item',
					width: '40%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '15%'
				}
			]
		}
	},
	methods: {
		backToOverview () {
			this.$router.push({
				name: EXTENDED_PREFERENCES,
				params: {
					accessedViaTranslation: true
				}
			})
		},
		async submit () {
			const extendedPreferenceTranslations = {
				languageID: this.extendedPreferenceTranslation.languageId,
				translatedAvailableValues: this.extendedPreferenceTranslation.translations.map(translation => ({
					valueId: translation.valueId,
					value: translation.value
				}))
			}
			await upsertExtendedPreferenceTranslation(extendedPreferenceTranslations)
			showSnackbar('Translation saved successfully')
			this.backToOverview()
		},
		manageDropdownTranslation (dropdownItem) {
			this.dropdownTranslationToEdit = dropdownItem
		},
		submitDropdownTranslation (dropdownTranslation) {
			this.dropdownTranslationToEdit = null
			// find the index of the dropdown item to edit in this.extendedPreferenceTranslation.dropdownValues
			const index = this.extendedPreferenceTranslation.translations.findIndex(
				item => item.valueId === dropdownTranslation.valueId
			)
			// if the dropdown item is found, update the translatedValue
			this.$set(this.extendedPreferenceTranslation.translations, index, dropdownTranslation)
		}
	}
}
</script>
