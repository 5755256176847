<template>
	<Modal width="600px">
		<template #modal-title>
			Extended Preference Translation
		</template>
		<template #modal-content>
			<v-row dense>
				<v-col cols="12">
					<TextField
						:value="languageName"
						disabled
						label="Language"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<TextField
						:value="dropdownTranslationToEdit.originalValue"
						disabled
						label="Dropdown Menu Item"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<TextField
						v-model="translation"
						label="Translation"
					/>
				</v-col>
			</v-row>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton @click="submitDropdownTranslation">
				{{ dropdownTranslationToEdit ? 'Save' : 'Create' }}
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'

export default {
	components: {
		Modal,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		dropdownTranslationToEdit: Object,
		languageName: String
	},
	data () {
		return {
			translation: JSON.parse(JSON.stringify(this.dropdownTranslationToEdit.value))
		}
	},
	computed: {
		isEdit () {
			return Boolean(this.dropdownTranslationToEdit.value)
		}
	},
	methods: {
		submitDropdownTranslation () {
			this.$emit('submitTranslation', {
				...this.dropdownTranslationToEdit,
				value: this.translation
			})
		}
	}
}
</script>
